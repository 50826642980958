import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
// import Service from "../views/Service.vue";
import PostConstruction from "../components/PostConstruction.vue";
import Industrial from "../components/Industrial.vue";
import Carpet from "../components/Carpet.vue";
import DeepClean from "../components/DeepClean.vue";
import Domestic from "../components/Domestic.vue";
import EventsCleaning from "../components/Events.vue";
import Handyman from "../components/Handyman.vue";
import Office from "../components/Commercial.vue";
import Windows from "../components/Windows.vue";
import Team from "../views/Team.vue";
import Covid from "../views/Covid.vue";
import Specials from "../views/Specials.vue";

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/post-construction",
    name: "PostConstruction",
    component: PostConstruction,
  },
  {
    path: "/industrial",
    name: "Industrial",
    component: Industrial,
  },
  {
    path: "/carpet",
    name: "Carpet",
    component: Carpet,
  },
  {
    path: "/deep-clean",
    name: "DeepClean",
    component: DeepClean,
  },
  {
    path: "/domestic",
    name: "Domestic",
    component: Domestic,
  },
  {
    path: "/events-cleaning",
    name: "EventsCleaning",
    component: EventsCleaning,
  },
  {
    path: "/handyman",
    name: "Handyman",
    component: Handyman,
  },
  {
    path: "/commercial",
    name: "Office",
    component: Office,
  },
  {
    path: "/windows",
    name: "Windows",
    component: Windows,
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
  },
  {
    path: "/covid",
    name: "Covid",
    component: Covid,
  },
  {
    path: "/specials",
    name: "Specials",
    component: Specials,
  },
  {
    path: "/*",
    name: "error",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
