<template>
  <div class="specials">
    <main role="main" class="">
      <!-- Jumbotron -->
      <section class=" jumbotron-fluid">
        <div class="container h-100">
          <div class="row">
            <div
              class="col-lg-12 h-100 d-flex flex-column justify-content-center"
            >
              <h1
                class="display-4 text-white text-center"
                style="padding-top:250px"
              >
                See The SmartCare Difference
              </h1>
              <!-- <a href="" class="btn btn-success btn-lg">Book a Clean</a> -->
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container bg-green sm-gt">
          <div class="row">
            <div class="col-md-6">
              <h2 class="text-white display-4 text-center pt-4">
                Get 10% OFF
              </h2>
              <h3 class="text-white text-center pb-4">
                When You Complete This Form
              </h3>
              <div class="row m-3">
                <form>
                  <div class="form-row">
                    <div class="col-md-12 mb-5">
                      <label for="inputEmail3" class="">Name</label>
                      <input
                        v-model="name"
                        type="text"
                        id="estimate-name"
                        class="form-control"
                        placeholder="name"
                      />
                    </div>
                    <div class="col-md-12 mb-5">
                      <label for="inputEmail3" class="">Phone</label>
                      <input
                        v-model="number"
                        type="text"
                        id="estimate-email"
                        class="form-control"
                        placeholder="1234567890"
                      />
                    </div>
                    <div class="col-md-12 mb-5">
                      <label for="inputEmail3" class="">Email</label>
                      <input
                        v-model="email"
                        type="text"
                        id="estimate-email"
                        class="form-control"
                        placeholder="name@email.co.za"
                      />
                    </div>
                    <div class="col-md-12 mb-5">
                      <label for="inputEmail3" class="">Service</label>
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="choice"
                      >
                        <option value="choose" selected>Choose...</option>
                        <option value="deep">Deep Clean</option>
                        <option value="construct"
                          >Post Construction Clean</option
                        >
                        <option value="carpet">Carpet Clean</option>
                        <option value="office">Office Clean</option>
                        <option value="window">Windows</option>
                        <option value="domestic">Domestic Service</option>
                        <option value="handyman">Handyman Service</option>
                        <option value="events">Post Event Clean</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div class="col-md-12 mb-5" v-if="choice === 'other'">
                      <label for="inputEmail3" class=""
                        >Please describe the clean you require</label
                      >
                      <textarea
                        v-model="otherInfo"
                        id="other"
                        name="other"
                        rows="4"
                        cols="50"
                      >
                      </textarea>
                    </div>
                    <div class="col-md-12 mb-5">
                      <label for="inputEmail3" class="">Select a date</label>
                      <input
                        v-model="date"
                        type="date"
                        id="estimate-email"
                        class="form-control"
                        placeholder="name@email.co.za"
                      />
                    </div>
                    <div class="col-12">
                      <button type="button" class="btn btn-link text-light p">
                        {{ error }}
                      </button>
                    </div>
                    <div class="col-md-4">
                      <button
                        type="button"
                        class="form-control btn-primary estimate-submit"
                        @click="sendBookingEmail()"
                      >
                        <div
                          v-if="loading"
                          class="spinner-border spinner-border-md"
                        ></div>
                        <span v-if="loading"> Sending</span>
                        <span v-else>{{ title }}</span>
                        <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div
                  class="card col-md-4"
                  v-for="(service, index) in services"
                  :key="index"
                >
                  <router-link :to="service.path">
                    <img
                      :src="'../services/' + service.image + '.webp'"
                      class="card-img-top img-fluid mt-3"
                      alt="..."
                    />
                    <div class="card-body">
                      <p class="card-title">{{ service.name }}</p>
                    </div></router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Team",
  data() {
    return {
      services: [
        {
          name: "Commercial Cleaning",
          image: "commercial",
          path: "./commercial",
        },
        {
          name: "Deep Cleaning",
          image: "deep-clean",
          path: "./deep-clean",
        },
        {
          name: "Carpet Cleaning",
          image: "carpet",
          path: "./carpet",
        },
        {
          name: "Window Cleaning",
          image: "windows",
          path: "./windows",
        },
        {
          name: "Domestic Services",
          image: "domestic",
          path: "./domestic",
        },
        {
          name: "Event Cleaning",
          image: "after-party",
          path: "./events-cleaning",
        },
        {
          name: "Post-Construction Cleaning",
          image: "post-construction",
          path: "./post-construction",
        },
        {
          name: "Industrial Cleaning",
          image: "industrial",
          path: "./industrial",
        },
        {
          name: "Handyman Services",
          image: "handyman",
          path: "./handyman",
        },
      ],
      loading: false,
      title: "Send",
      error: "",
      choice: "choose",
      name: "",
      number: "",
      email: "",
      date: "",
      otherInfo: "",
    };
  },
  methods: {
    sendBookingEmail() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/sendSpecialBooking.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              number: this.number,
              choice: this.choice,
              date: this.date,
              otherInfo: this.otherInfo,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.number = "";
            this.choice = "";
            this.date = "";
            this.otherInfo = "";
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
  },
};
</script>
