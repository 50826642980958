<template>
  <div class="home">
    <main role="main" class="">
      <!-- Jumbotron -->
      <section class="jumbotron-team jumbotron-fluid">
        <div class="container h-100">
          <div
            class="col-lg-12 h-100 d-flex flex-column justify-content-center"
          >
            <h1
              class="display-4 text-white text-center"
              style="padding-top:250px"
            >
              Our Amazing Team
            </h1>
            <!-- <a href="" class="btn btn-success btn-lg">Book a Clean</a> -->
          </div>
        </div>
      </section>

      <section class="home-section-2 text-center">
        <div class="container">
          <div class="row the-team  d-flex justify-content-center">
            <!-- <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Rebecca Tara Cameron1.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Rebecca Tara Cameron</h4>
            </div> -->

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Siphiwe Marlvine Maneli.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Siphiwe Marlvine Maneli</h4>
            </div>
          </div>
          <div class="row the-team d-flex justify-content-center">
            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Brigit Nyasha Njunga1.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Brigit Nyasha Njunga</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Busiswa Pretty Mpeto1.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Busiswa Pretty Mpeto</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Charleau Dakura1.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Charleau Dakura</h4>
            </div>

            <div class="col-lg-3 pb-5 pb-5">
              <img
                src="../assets/team/Eunice Mafuriranwa1.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Eunice Mafuriranwa</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Ivy Teresa Mushawenyoka1.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Ivy Teresa Mushawenyoka</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/John Mupetami1.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>John Mupetami</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Pretty Shereni1.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Pretty Shereni</h4>
            </div>
            <!-- NEW -->

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Johnson.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Johnson Nkosi</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Kelly Binari.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Kelly Binari</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Rachel Salbot.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Rachel Salbot</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Esther Kotosa.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Esther Kotosa</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Mac-Mill.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Mac-Mill Makwetu</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Takudzwa Nyemba.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Takudzwa Nyemba</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/not-sure.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Chipo Kabayi</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Israel Matsongoni.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Israel Matsongoni</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Michelle Ndlovu.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Michelle Ndlovu</h4>
            </div>

            <div class="col-lg-3 pb-5">
              <img
                src="../assets/team/Shuwai Chigudi.webp"
                class="img-fluid img-team"
                alt=""
              />
              <h4>Shuwai Chigudi</h4>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container pt-5 pb-5">
          <div
            class="row"
            id="gallery"
            data-toggle="modal"
            data-target="#teams"
          >
            <div class="col-6 col-md-4 col-lg-4 pt-5">
              <img
                class="w-100"
                src="../assets/gallery/dancing.webp"
                data-target="#carouselExample"
                data-slide-to="0"
              />
            </div>
            <div class="col-6 col-md-4 col-lg-4 pt-5">
              <img
                class="w-100"
                src="../assets/gallery/dancing1.webp"
                data-target="#carouselExample"
                data-slide-to="1"
              />
            </div>
            <div class="col-6 col-md-4 col-lg-4 pt-5">
              <img
                class="w-100"
                src="../assets/gallery/dancing2.webp"
                data-target="#carouselExample"
                data-slide-to="2"
              />
            </div>
            <div class="col-6 col-md-4 col-lg-4 pt-5">
              <img
                class="w-100"
                src="../assets/gallery/next-generation.webp"
                data-target="#carouselExample"
                data-slide-to="3"
              />
            </div>
            <div class="col-6 col-md-4 col-lg-4 pt-5">
              <img
                class="w-100"
                src="../assets/gallery/the-team.webp"
                data-target="#carouselExample"
                data-slide-to="4"
              />
            </div>
            <div class="col-6 col-md-4 col-lg-4 pt-5">
              <img
                class="w-100"
                src="../assets/gallery/training.webp"
                data-target="#carouselExample"
                data-slide-to="5"
              />
            </div>
          </div>
          <div
            class="modal fade"
            id="teams"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog  modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <!-- Carousel markup goes in the modal body -->

                  <div
                    id="carouselExample"
                    class="carousel slide"
                    data-ride="carousel"
                  >
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img
                          class="d-block w-100"
                          src="../assets/gallery/dancing.webp"
                        />
                      </div>
                      <div class="carousel-item">
                        <img
                          class="d-block w-100"
                          src="../assets/gallery/dancing1.webp"
                        />
                      </div>
                      <div class="carousel-item">
                        <img
                          class="d-block w-100"
                          src="../assets/gallery/dancing2.webp"
                        />
                      </div>
                      <div class="carousel-item">
                        <img
                          class="d-block w-100"
                          src="../assets/gallery/next-generation.webp"
                        />
                      </div>
                      <div class="carousel-item">
                        <img
                          class="d-block w-100"
                          src="../assets/gallery/the-team.webp"
                        />
                      </div>
                      <div class="carousel-item">
                        <img
                          class="d-block w-100"
                          src="../assets/gallery/training.webp"
                        />
                      </div>
                    </div>
                    <a
                      class="carousel-control-prev"
                      href="#carouselExample"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a
                      class="carousel-control-next"
                      href="#carouselExample"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal1"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import $ from "jquery";
$(function() {
  $("#mdb-lightbox-ui").load("mdb-addons/mdb-lightbox-ui.html");
});

export default {
  name: "Team",
};
</script>
