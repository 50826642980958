<template>
  <div class="home text-left">
    <main role="main" class="">
      <section class="hero-4">
        <div class="container hero h-100">
          <div class="row h-100 d-flex align-items-center">
            <div class="col-md-12 text-center">
              <h1 class="display-4 hero-heading text-white">
                Meet<br />
                <strong>SmartCare Cleaning</strong>
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section class="home-section-3 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-md-12"></div>

            <div class="col-md-7">
              <hr class="title-hr" />
              <h2 class="display-2 pb-5 font-weight-bold">
                Meet Marlvine
              </h2>
              <p class="  text-lg-justify">
                “I am the face of SmartCare and and oversee the cleaning
                operations. I am 37 years old and a father to 3 wonderful boys.
                I am passionate about entrepreneurship and, during my short
                lifetime, have started several businesses.
              </p>
              <p>
                After being exposed to the cleaning industry in 2017, I believed
                that I could add some value to the industry and after some
                research started Smartcare Cleaning Services. In this industry,
                we are lucky enough to be able to add value to other people's
                lives through employment and through our services.”
              </p>
            </div>
            <div class="col-md-5">
              <img
                src="../assets/marlvine-smartcare-cleaning.webp"
                class="img-fluid pt-5"
                alt="Marlvine SmartCare Cleaning"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="home-section-3 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <hr class="title-hr" />
              <h2 class="display-2 pb-3 font-weight-bold">
                Our Values
              </h2>
              <h4 class="pb-5">At SmartCare cleaning, we take pride on:</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h3 class="pb-3 text-uppercase">Mindfulness</h3>
              <p class="  text-lg-justify">
                We are all on an exciting and occasionally arduous journey of
                self-discovery. We recognise the value of being present in the
                moment, of a clean mind.
              </p>
              <p>
                A clean space facilitates this journey. A clean space has a
                whole host of benefits, including a happier state of mind. By
                keeping your space clean, you remove all kinds of physical and
                mental stressors.
              </p>
              <p>
                Ultimately, a clean space is the start of a mindful life. At
                SmartCare, we want to help facilitate this journey.
              </p>
            </div>
            <div class="col-md-12">
              <h3 class="pb-3 pt-5 text-uppercase">One-stop shop</h3>
              <p class="  text-lg-justify">
                We value long-term relationships. SmartCare Cleaning recognises
                that everyone is different and ‘one-size-fits-all’ solutions
                rarely meet your needs. We are committed to designing solutions
                for you, tailored to your space, budget, and timing.
              </p>
              <p>
                Our flexible, broad service offering means there is no need for
                multiple service providers. We can take care of the hassle for
                you. Do you need your car washed? Mattress upholstered? What
                about the mold on the wall, or your favourite mirror hung up? We
                can do it all
              </p>
            </div>
            <div class="col-md-12">
              <h3 class="pb-3 pt-5 text-uppercase">
                Quality people - Social Upliftment
              </h3>
              <p class="  text-lg-justify">
                Our people are the key to who we are. They are hard-working,
                kind, and diligent. We value empowering, developing, and
                believing in our staff. Often cleaning is seen as a “cheap”
                service and cleaners are exploited by being paid very little. At
                SmartCare, we treat our staff well, and include transport and
                food for our staff on the day. We give them leadership
                opportunities and training, and believe in their success.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Home",
  metaInfo: {
    title: "SmartCare Cleaning | Cleaning Services Cape Town",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      {
        name: "description",
        content:
          "About SmartCare Cleaning | we are a cleaning company based in Cape Town that takes pride in offering professional cleaning services",
      },
      {
        property: "og:title",
        content: "About SmartCare Cleaning | Cleaning Services Cape Town",
      },
      {
        property: "og:site_name",
        content: "About SmartCare Cleaning",
      },
      { property: "og:type", content: "website" },
    ],
  },
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      choice: "choose",
      name: "",
      email: "",
      date: "",
    };
  },
  methods: {
    requestEstimate() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/getEstimate.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              choice: this.choice,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.choice = "";
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
  },
};
</script>
<style></style>
