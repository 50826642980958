<template>
  <div class="home text-left">
    <main role="main" class="">
      <section class="hero-1">
        <div class="container hero ">
          <div class="row align-items-center">
            <div class="col-md-6 align-middle">
              <!-- <h1 class="display-4 hero-heading text-white">
                Deep cleaning specialists for all property types
              </h1>
               -->
              <h3 class="display-4 hero-heading text-white">
                Deep Cleaning Specialists
              </h3>
              <button
                class="btn btn-success btn-xl"
                data-toggle="modal"
                data-target="#bookModal"
              >
                Book a SmartCare Cleaning today
              </button>
            </div>
            <div class="col-md-6 p-5 "></div>
          </div>
        </div>
      </section>
      <section class="estimate bg-blue p-5">
        <div class="container">
          <hr class="title-hr" />
          <h2 class="display-2 mb-5 font-weight-bold text-white">
            Request an estimate
          </h2>
          <form>
            <div class="form-row">
              <div class="col-md-3 mb-3">
                <label for="inputEmail3" class="">Name</label>
                <input
                  v-model="name"
                  type="text"
                  id="estimate-name"
                  class="form-control"
                  placeholder="name"
                />
              </div>
              <div class="col-md-3 mb-3">
                <label for="inputEmail3" class="">Email</label>
                <input
                  v-model="email"
                  type="text"
                  id="estimate-email"
                  class="form-control"
                  placeholder="name@email.co.za"
                />
              </div>
              <div class="col-md-3 mb-3">
                <label for="inputEmail3" class="">Service</label>
                <select id="inputState" class="form-control" v-model="choice">
                  <option value="choose" selected>Choose...</option>
                  <option value="deep">Deep Clean</option>
                  <option value="construct">Post Construction Clean</option>
                  <option value="carpet">Carpet Clean</option>
                  <option value="office">Office Clean</option>
                  <option value="window">Windows</option>
                  <option value="domestic">Domestic Service</option>
                  <option value="handyman">Handyman Service</option>
                  <option value="events">Post Event Clean</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div class="col-md-3">
                <button
                  type="button"
                  class="form-control btn-success estimate-submit"
                  @click="requestEstimate()"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-md"
                  ></div>
                  <span v-if="loading"> Sending</span>
                  <span v-else>{{ title }}</span>
                  <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
                </button>
              </div>
              <div class="col-12">
                <button type="button" class="btn btn-link text-light p">
                  {{ error }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>

      <!-- First Section -->
      <section class="home-section-1">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-12 mb-5  text-lg-justify">
              <div class=" pb-5">
                <hr class="title-hr" />
                <h2 class="display-2 pb-5 font-weight-bold">
                  Did you know a dirty space can affect your health?
                </h2>
                <p>
                  Spaces that aren’t cleaned regularly can lead to a whole host
                  of health problems for you and your family. Dust, animal hair
                  and dirt can lead to allergies, asthma, and the spread of
                  viral infections. In the office, untidy spaces can lead to
                  increased sick leave and decreased productivity.
                </p>
              </div>
              <div class="pt-3">
                <hr class="title-hr" />
                <h2 class="display-2 pb-5 font-weight-bold">
                  Protect yourself with SmartCare Cleaning
                </h2>
                <p>
                  SmartCare cleaning offers professional and high-quality
                  cleaning services in Cape Town. Whether you have a residential
                  home that needs cleaning or a commercial property, our team of
                  expertly trained and friendly cleaning staff will have your
                  space beautifully clean in no time. For peace of mind, choose
                  SmartCare Cleaning today.
                </p>
              </div>
            </div>

            <div
              class="col-lg-4 offset-lg-1 col-12 d-flex justify-content-around flex-column text-center"
            >
              <div class="row pb-4 d-flex justify-content-sm-center">
                <div class="col-md-4 col-5">
                  <img
                    src="../assets/Deep cleaning.svg"
                    alt="Smartcare Cleaning offers deep cleaning for residential and commercial property"
                  />
                </div>
                <div class="col-md-8 col-7 d-md-flex align-items-center">
                  <h5 class="text-lg-left text-center">
                    Deep Cleaning For Residential And Commercial Property
                  </h5>
                </div>
              </div>

              <div class="row pb-4 d-flex justify-content-sm-center">
                <div class="col-md-4 col-5">
                  <img
                    src="../assets/office cleaning.svg"
                    alt="Smartcare Cleaning offers office cleaning"
                  />
                </div>
                <div class="col-md-8 col-7 d-md-flex align-items-center">
                  <h5 class="text-lg-left text-center">Office Cleaning</h5>
                </div>
              </div>

              <div class="row pb-4 d-flex justify-content-sm-center">
                <div class="col-md-4 col-5">
                  <img
                    src="../assets/Domestic Services.svg"
                    alt="Smartcare Cleaning offers domestic cleaning"
                  />
                </div>
                <div class="col-md-8 col-7 d-md-flex align-items-center">
                  <h5 class="text-lg-left text-center">Domestic Services</h5>
                </div>
              </div>

              <div class="row pb-4 d-flex justify-content-sm-center">
                <div class="col-md-4 col-5">
                  <img
                    src="../assets/CarpetCleaning.svg"
                    alt="Smartcare Cleaning offers carpet cleaning"
                  />
                </div>
                <div class="col-md-8 col-7 d-md-flex align-items-center">
                  <h5 class="text-lg-left text-center">
                    Carpet Cleaning
                  </h5>
                </div>
              </div>

              <div class="row pb-4 d-flex justify-content-sm-center">
                <div class="col-md-4 col-5 ">
                  <img
                    src="../assets/Window Cleaning.svg"
                    alt="Smartcare Cleaning offers windows cleaning"
                  />
                </div>
                <div class="col-md-8 col-7 d-md-flex align-items-center ">
                  <h5 class="text-lg-left text-center">Window Cleaning</h5>
                </div>
              </div>

              <div class="row pb-4 d-flex justify-content-sm-center">
                <div class="col-md-4 col-5">
                  <img
                    src="../assets/Handyman.svg"
                    alt="Smartcare Cleaning offers handyman services"
                  />
                </div>
                <div class="col-md-8 col-7 d-md-flex align-items-center">
                  <h5 class="text-lg-left text-center align-self-center">
                    Handyman Services
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="card bg-green eco col-md-12 mt-5">
            <div class="card-body text-white row">
              <div class="col-md-2">
                <img
                  src="../assets/eco-freindly.svg"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="col-md-10 my-auto">
                <h2 class="display-2 text-white pb-3 font-weight-bold">
                  Go green with our Eco-option
                </h2>
                <p class="card-text text-white text-md-justify">
                  You can choose any of our cleaning service offerings as an
                  Eco-option! We use bio-degradable & eco-friendly household
                  cleaning products, safe for grey water systems & septic tanks.
                  Locally produced, tried & tested products we know you’ll love!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="home-section-how-it-works">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <hr class="title-hr" />
              <h2 class="display-2 pb-5 font-weight-bold">
                4 Steps To The Perfect Clean
              </h2>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-8"></div>
          </div>
          <div class="row text-center">
            <div class="col-lg-3 col-sm-6 mb-5 steps-img">
              <h3 class="pb-4">Step One</h3>
              <img src="../assets/booking1.svg" class="img-fluid pb-4" alt="" />
              <p class="text-center">
                Tell us what cleaning service you want and what you need
              </p>
            </div>
            <div class="col-lg-3 col-sm-6 mb-5 steps-img">
              <h3 class="pb-4">Step Two</h3>
              <img src="../assets/quote1.svg" class="img-fluid pb-4" alt="" />
              <p class="text-center">
                Receive a quote within 24hrs
              </p>
            </div>
            <div class="col-lg-3 col-sm-6 mb-5 steps-img">
              <h3 class="pb-4">Step Three</h3>
              <img
                src="../assets/deposit1.svg"
                class="img-fluid  pb-4"
                alt=""
              />
              <p class="text-center">
                Pay the deposit to secure your clean
              </p>
            </div>
            <div class="col-lg-3 col-sm-6 mb-5 steps-img">
              <h3 class="pb-4">Step Four</h3>
              <img src="../assets/relax1.svg" class="img-fluid pb-4" alt="" />
              <p class="text-center">
                Sit back and relax while SmartCare Cleaning Services takes care
                of the rest
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="home-section-2">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <hr class="title-hr" />
              <h2 class="display-2 pb-5 font-weight-bold">
                SmartCare Cleaning Reviews
              </h2>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-8"></div>
          </div>
          <div class="row no-padding">
            <div id="carousel-1" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li
                  data-target="#carousel-1"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li data-target="#carousel-1" data-slide-to="1"></li>
                <li data-target="#carousel-1" data-slide-to="2"></li>
              </ol>
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <div class="col-lg-12">
                    <div class="card card-testimonial h-100">
                      <div
                        class="card-body d-flex justify-content-around flex-column"
                      >
                        <p
                          class="card-text font-italic  text-lg-justify align-self-center"
                        >
                          <span><i class="fas fa-quote-left"></i></span> A smart
                          care team came to do a deep clean for my flat, and I
                          was absolutely blown away by the service! It’s a great
                          challenge to find a trustworthy and professional
                          cleaning service that really gets the job done, so I
                          am thrilled to have found exactly that! Would highly
                          recommend to anyone.
                          <span><i class="fas fa-quote-right"></i></span>
                        </p>
                        <h5 class="text-green-test align-self-center pb-3">
                          Bridget Witts-Hewinson
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="col-lg-12">
                    <div
                      class="card card-testimonial d-flex align-items-stretch"
                    >
                      <div
                        class="card-body d-flex justify-content-around flex-column"
                      >
                        <p
                          class="card-text font-italic text-lg-justify align-self-center"
                        >
                          <span><i class="fas fa-quote-left"></i></span> I
                          needed a good deep clean for my flat after being in
                          hibernation, following lockdown. I asked Rebecca and
                          her team to come in and help me. All I can say is WOW!
                          I was very impressed with the friendly staff who
                          actually seemed happy to be cleaning which made me
                          feel very comfortable. They came in in a team of 5 and
                          managed to clean the entire flat which included
                          windows, kitchen grime from cooking, cupboards,
                          staircase rails and so much more. It was very
                          affordable and they did a great job. Overall I felt
                          really good to be supporting a local business that not
                          only did a fantastic job but did it with a smile on
                          their faces. Highly recommend
                          <span><i class="fas fa-quote-right"></i></span>
                        </p>
                        <h5 class="text-green-test align-self-center pb-3">
                          Kelsey Bishton
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="col-lg-12">
                    <div class="card card-testimonial h-100">
                      <div
                        class="card-body d-flex justify-content-around flex-column"
                      >
                        <p
                          class="card-text font-italic text-md-justify text-center align-self-center"
                        >
                          <span><i class="fas fa-quote-left"></i></span>
                          Smartcare, are professional, friendly, flexible and
                          care about their clients. The products they use are
                          not toxic or damaging as most store bought are. I
                          recommend Smartcare to friends and acquaintances all
                          the time because I know they will be as happy with
                          their wonderful service, staff and products as I have
                          been. In these covid times, be safe, hire a
                          professional. Do yourself a favor, hire Smartcare and
                          you will love them as much as we do!
                          <span><i class="fas fa-quote-right"></i></span>
                        </p>
                        <h5 class="text-green-test align-self-center pb-3">
                          Lloyd Mushina
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <a
                class="carousel-control-prev d-flex align-items-stretch"
                href="#carousel-1"
                role="button"
                data-slide="prev"
              >
                <span class="text-blue" aria-hidden="true"
                  ><i class="fas fa-arrow-circle-left fa-4x"></i
                ></span>
                <span class="sr-only">prev</span>
              </a>

              <a
                class="carousel-control-next d-flex align-items-stretch"
                href="#carousel-1"
                role="button"
                data-slide="next"
              >
                <span aria-hidden="true"
                  ><i class="fas fa-arrow-circle-right fa-4x"></i
                ></span>
                <span class="sr-only">next</span>
              </a> -->
            </div>
          </div>
          <!-- e carousel -->
        </div>
      </section>

      <!-- <section class="home-section-3 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h3 class="pb-3 pt-5">HOW WE SCREEN STAFF:</h3>
              <p class="  text-lg-justify">
                We also understand the deeply personal nature of cleaning
                services, and ensure to deliver a discreet, respectful and safe
                service. New staff are taken through a stringent hiring process,
                including all the necessary background and reference checks plus
                a compulsory training period.
              </p>
            </div>
          </div>
        </div>
      </section> -->

      <!-- <section>
        <div class="container bg-green rounded">
          <form class=" p-3">
            <h3 class="">
              Download Cleaning smart with SmartCare Cleaning
            </h3>
            <h5 class="">
              A Smart Cleaning Guide
            </h5>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label"
                >Email address</label
              >
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                v-model="downloadEmail"
              />
              <div id="emailHelp" class="form-text">
                We'll never share your email with anyone else.
              </div>
            </div>
            <div class="mb-3 form-check">
              <input
                type="checkbox"
                class="form-check-input mt-3"
                id="exampleCheck1"
                v-model="joinMailing"
              />
              <label class="form-check-label3 pl-3" for="exampleCheck1">
                I would like to recieve future cleaning tips and tricks</label
              >
            </div>
            <p>
              <button
                v-if="downloadEmail == ''"
                class="btn btn-success-alt p"
                disabled
              >
                {{ listTitle }}
              </button>
              <a
                v-if="downloadEmail != ''"
                href="./Cleaning smart with SmartCare Cleaning.pdf"
                target="_blank"
                class="btn btn-success-alt p"
                @click="sendInfo()"
              >
                {{ listTitle }}
              </a>
            </p>
          </form>
        </div>
      </section> -->

      <section class="container">
        <div class=" bg-green col-md-12 eco">
          <!-- Begin Mailchimp Signup Form -->
          <div id="mc_embed_signup" class="py-4">
            <form
              action="https://smartcarecleaning.us7.list-manage.com/subscribe/post?u=68ee592ac162eadfa1f2a5dd8&amp;id=027635dec7&amp;f_id=00eac6e4f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_self"
            >
              <div id="mc_embed_signup_scroll">
                <h2 class="display-2 text-white pb-3 font-weight-bold">
                  Subscribe To Our Newsletter
                </h2>
                <p class="text-white text-shadow pb-3">
                  Enter your email address to receive cleaning tips and tricks
                  from the experts at SmartCare Cleaning.
                </p>
                <div class="mc-field-group">
                  <label for="mce-EMAIL"
                    >Email Address <span class="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    value=""
                    name="EMAIL"
                    class="required email form-control"
                    id="mce-EMAIL"
                    required
                  />
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
                </div>
                <div class="indicates-required">
                  <span class="asterisk">*</span> indicates required
                </div>
                <div id="mce-responses" class="clear foot">
                  <div
                    class="response"
                    id="mce-error-response"
                    style="display:none"
                  ></div>
                  <div
                    class="response"
                    id="mce-success-response"
                    style="display:none"
                  ></div>
                </div>
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div
                  style="position: absolute; left: -5000px;"
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_68ee592ac162eadfa1f2a5dd8_027635dec7"
                    tabindex="-1"
                    value=""
                  />
                </div>
                <div class="optionalParent pt-3">
                  <div class="clear foot">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      class="button btn btn-primary text-light p"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!--End mc_embed_signup-->
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Home",
  metaInfo: {
    title: "SmartCare Cleaning | Cleaning Services Cape Town",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      {
        name: "description",
        content:
          "SmartCare Cleaning offers a range of cleaning services tailored to commercial and residential properties throughout the Western Cape. ",
      },
      {
        property: "og:title",
        content: "SmartCare Cleaning | Cleaning Services Cape Town",
      },
      {
        property: "og:site_name",
        content: "SmartCare Cleaning",
      },
      { property: "og:type", content: "website" },
    ],
  },
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      choice: "choose",
      name: "",
      email: "",
      date: "",
      downloadEmail: "",
      joinMailing: false,
      listTitle: "Download",
    };
  },
  methods: {
    requestEstimate() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/getEstimate.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              choice: this.choice,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.choice = "";
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    sendInfo() {
      axios
        .post(
          "/phpmailer/src/mailingList.php",

          qs.stringify({
            ctaEmail: this.downloadEmail,
            joinList: this.joinMailing,
          })
        )
        .then((response) => {
          this.loading = !false;
          this.title = "Downloading :)";
          setTimeout(() => (this.title = "Download"), 5000);
          setTimeout(() => {
            this.loading = !true;
          }, 2000);
          this.downloadEmail = "";
          this.choice = false;
          console.log(response);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>
<style></style>
