<template>
  <div class="main-nav">
    <div class="bg-darkblue fixed-top">
      <router-link class="navbar-brand" to="/"
        ><img
          src="../assets/logo.svg"
          class="img-fluid logo mobile-logo"
          id="logo"
          alt=""
      /></router-link>
    </div>

    <div class="navigation mobile-version">
      <div class="ham-btn" onclick="showNav()">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="links">
        <div class="link" onclick="showNav()">
          <router-link
            class="nav-link"
            to="/"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            ><h3>Home</h3>
          </router-link>
        </div>
        <div class="link" onclick="showNav()">
          <router-link
            class="nav-link"
            to="/about"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <h3>About Us</h3>
          </router-link>
        </div>
        <hr />
        <h3 disabled class="disabled">Cleaning Services</h3>
        <hr />
        <div class="link" onclick="showNav()">
          <router-link
            class="dropdown-item"
            to="./deep-clean"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            ><p class="text-center">Deep Cleaning</p></router-link
          >
        </div>
        <div class="link" onclick="showNav()">
          <router-link
            class="dropdown-item"
            to="./commercial"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            ><p class="text-center">Commercial Cleaning</p></router-link
          >
        </div>
        <div class="link" onclick="showNav()">
          <router-link
            class="dropdown-item"
            to="./carpet"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            ><p class="text-center">Carpet Cleaning</p>
          </router-link>
        </div>
        <div class="link" onclick="showNav()">
          <router-link
            class="dropdown-item"
            to="./domestic"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            ><p class="text-center">Window Cleaning</p></router-link
          >
        </div>
        <div class="link" onclick="showNav()">
          <router-link
            class="dropdown-item"
            to="./windows"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            ><p class="text-center">Domestic Services</p></router-link
          >
        </div>

        <div class="link" onclick="showNav()">
          <router-link
            class="dropdown-item"
            to="./events-cleaning"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            ><p class="text-center">Event Cleaning</p>
          </router-link>
        </div>
        <div class="link" onclick="showNav()">
          <router-link
            class="dropdown-item"
            to="./post-construction"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            ><p class="text-center">Post-construction Cleaning</p>
          </router-link>
        </div>
        <div class="link" onclick="showNav()">
          <router-link
            class="dropdown-item"
            to="/industrial"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            ><p class="text-center">Industrial Cleaning</p>
          </router-link>
        </div>

        <div class="link" onclick="showNav()">
          <router-link
            class="nav-link"
            to="/team"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <hr />
            <h3>The Team</h3>
          </router-link>
        </div>

        <div class="link" onclick="showNav()">
          <a
            class="nav-link"
            href="#footer"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            v-smooth-scroll
          >
            <h3>Contact</h3></a
          >
        </div>
      </div>
    </div>
    <div class="navbar navbar-dark bg-green desk-top-nav-top fixed-top">
      <div class="container">
        <ul class="navbar-nav flex-row d-flex justify-content-between w-100">
          <li class="nav-item">
            <a class="nav-link" href="tel:087 133 4411"
              >Cell: +27 87 133 4411</a
            >
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="tel:0210231390">Landline: 021 023 1390</a>
          </li> -->
          <li class="nav-item">
            <a
              class="nav-link"
              href="mailto:info@smartcarecleaning.com"
              target="_blank"
              >E-mail: info@smartcarecleaning.com</a
            >
          </li>
          <li class="nav-item">
            <button
              class="nav-link btn btn-primary cta-top"
              data-toggle="modal"
              data-target="#bookModal"
            >
              Book Now
            </button>
          </li>
        </ul>
      </div>
    </div>
    <nav class=" navbar navbar-expand-lg bg-none fixed-top" id="navbar-main">
      <div class="container">
        <router-link class="navbar-brand" to="/"
          ><img
            src="../assets/logo.svg"
            class="img-fluid logo"
            id="logo"
            alt=""
          />
          <p class="text-center">
            <small class="text-blue font-weight-bold"
              >Clean Space - Clean Mind
            </small>
          </p></router-link
        >
        <!-- <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fas fa-bars fa-2x mt-5"></i>
        </button> -->

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav second-nav text-center">
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/about"
                data-toggle="collapse"
                data-target=".navbar-collapse"
                >About Us</router-link
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link
                  class="dropdown-item"
                  to="./deep-clean"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  >Deep cleaning</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="./commercial"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  >Commercial Cleaning</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="./carpet"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  >Carpet Cleaning</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="./windows"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  >Window Cleaning</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="./domestic"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  >Domestic Services</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="./events-cleaning"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  >Event Cleaning</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="./post-construction"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  >Post-construction Cleaning</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/industrial"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  >Industrial Cleaning</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="./handyman"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  >Handyman Services</router-link
                >
              </div>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/team"
                data-toggle="collapse"
                data-target=".navbar-collapse"
                >The Team</router-link
              >
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                href="#footer"
                data-toggle="collapse"
                data-target=".navbar-collapse"
                v-smooth-scroll
                >Contact</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.facebook.com/smartcarecleaning.co.za"
                target="_blank"
                ><i class="fab fa-facebook"></i
              ></a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.instagram.com/smartcarecleaningct/"
                target="_blank"
                ><i class="fab fa-instagram-square"></i
              ></a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.linkedin.com/company/smartcare-cleaning/"
                target="_blank"
                ><i class="fab fa-linkedin"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <a href="https://wa.me/27671529802" target="_blank" class="float">
      <i class="fab fa-whatsapp my-float fa-3x"></i>
    </a>
  </div>
</template>
<script>
import $ from "jquery";
$(document).on("scroll", function() {
  if ($(document).scrollTop() > 30) {
    $("#navbar-main").addClass("shrink");
  } else {
    $("#navbar-main").removeClass("shrink");
  }
});
export default {
  name: "Header",
};
</script>
