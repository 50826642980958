import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSmoothScroll from 'vue2-smooth-scroll';

 

Vue.use(VueSmoothScroll, {
  duration: 400,
  updateHistory: false,
})
Vue.config.productionTip = false;
Vue.component(
  "Header",
  require("./components/Header.vue").default
);
Vue.component(
  "Footer",
  require("./components/Footer.vue").default
);
Vue.component(
  "Modal",
  require("./components/Modal.vue").default
);
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
