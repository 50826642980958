<template>
  <div class="Covid">
    <main rlie="main" class="">
      <!-- Jumbotron -->
      <section class="jumbotron-covid jumbotron-fluid">
        <div class="container h-100">
          <div
            class="col-lg-12 h-100 d-flex flex-column justify-content-center"
          >
            <h1 class="display-4 text-white text-center pt-5">
              Stay Safe<br>Stay Healthy
            </h1>
            <!-- <a href="" class="btn btn-success btn-lg">Book a Clean</a> -->
          </div>
        </div>
      </section>

      <section class="home-section-2">
        <div class="container">
          <div class="row mb-5">
            <div class="col-lg-6">
              <h3 class="pb-4">
              To keep you safe, we have a clear policy to reduce the risk of
              Covid-19 infections. There are five principles:
            </h3>
            <p>
              <ul class="list-unstyled">
                <li class="pb-3">
                  1. We take each team member’s temperature in the morning before
                  they start work.
                </li>
                <li class="pb-3">
                  2. We take and fetch our team, so they don’t have to take public
                  transport.
                </li>
                <li class="pb-3">
                  3. Each team member wears gloves and facemasks and we’re all
                  trained in how to put them on, take them off, and throw them
                  away safely.
                </li>
                <li class="pb-3">
                  4. Each team member has their own sanitiser, and we use it a lot.
                </li>
                <li class="pb-3">
                  5. If we’re touching a surface a lot (like door handles, light
                  switches), we disinfect it regularly.
                </li>
                <li class="pb-3">
                  6. We don’t let anyone work if they show symptoms - and we ask
                  our clients to have an active rlie in reporting any concerns to
                  us.
                </li>
              </ul>
            </p>
            </div>
            <div class="col-lg-6"><img src="../assets/covid.webp" class="img-fluid" alt=""></div>
            
           
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "Covid",
};
</script>
