<template>
  <section class="services">
    <div class="container">
      <div class="row">
        <!-- section3 -->
        <div class="col-lg-6 service-section">
          <hr class="title-hr" />
          <h2 class="display-3 pb-5 font-weight-bold">
            Carpet Cleaning and Furniture Upholstery Services
          </h2>
          <p class=" text-justify">
            Do you have a tough and stubborn stain on your carpet that you
            cannot get rid of? Is it post-occupation carpet-cleaning time? Have
            you ever cleaned your couch before.
          </p>
          <br />
          <p class=" text-justify">
            We use a steam-cleaning method which ensures hot water penetrates
            deep into the carpet fibres and the high-pressure suction pump sucks
            up the moisture which lifts up the dirt and dust mites from deep
            inside the carpet fibres. The suction action cleaning is reinforced
            by our germ killing chemicals which are dissolved in the hot water
            used for cleaning.
          </p>
        </div>
        <div class="col-lg-6 service-section">
          <img src="../assets/services/carpet.webp" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Carpet",
};
</script>
