<template>
  <footer class="bg-dark mt-5" id="footer">
    <!-- <div class="bg-dark p-2 text-white text-center">
      <router-link to="/covid" class="btn btn-covid"
        ><p class="pt-2 text-white">View Our Covid Policies</p></router-link
      >
    </div> -->
    <div class="container py-5">
      <section class="mb-4">
        <!-- <h1
          class="display-4 font-weight-bold text-center my-5 text-capitalize text-white"
        >
          We would love to hear from you
        </h1> -->
        <div class="row">
          <div
            class="col-md-4 text-left text-white pb-5 mb-5 footer-contact-info border-right"
          >
            <img
              src="../assets/logo.svg"
              class="img-fluid logo pb-4"
              id="logo"
              alt=""
            />
            <p class="text-white text-left">
              SmartCare Cleaning offers a range of cleaning services tailored to
              commercial and residential properties throughout the Western Cape.
              Clear Space, Clear Mind.
            </p>
          </div>
          <div
            class="col-md-4 text-left text-white pb-5 mb-5 footer-contact-info border-right"
          >
            <div>
              <h2 class="h3 footer-sub-heading text-white">
                Contact SmartCare Cleaning
              </h2>
              <ul class="list-unstyled mb-0">
                <li>
                  <a href="tel:087 133 4411" class="footer-contact"
                    ><span><i class="fas fa-mobile-alt mt-4"></i></span>087 133
                    4411</a
                  >
                </li>
                <li>
                  <a
                    href="mailto:info@smartcarecleaning.com"
                    class="footer-contact"
                    ><span><i class="fas fa-envelope mt-4"></i></span>info@<br
                      class="desktop-email-footer-break"
                    />smartcarecleaning.com</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/smartcarecleaning.co.za"
                    target="_blank"
                    class="footer-contact"
                    ><span><i class="fab fa-facebook mt-4"></i></span
                    >@smartcarecleaning.co.za</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/smartcarecleaningct/"
                    target="_blank"
                    class="footer-contact"
                    ><span><i class="fab fa-instagram-square mt-4"></i></span
                    >@smartcarecleaningct</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/smartcare-cleaning/"
                    target="_blank"
                    class="footer-contact"
                    ><span><i class="fab fa-linkedin mt-4"></i></span
                    >@smartcare-cleaning</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 mb-md-0 mb-5">
            <form>
              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label for="name" class="">Name</label>
                    <input
                      v-model="name"
                      type="text"
                      id="name"
                      name="name"
                      class="form-control"
                    />
                  </div>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label for="email" class="">Phone</label>
                    <input
                      v-model="number"
                      type="text"
                      id="email"
                      name="email"
                      class="form-control"
                    />
                  </div>
                </div>
                <!--Grid column-->
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-0">
                    <label for="subject" class="">Email</label>
                    <input
                      v-model="email"
                      type="text"
                      id="subject"
                      name="subject"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="message">How can we help you</label>
                    <textarea
                      v-model="message"
                      type="text"
                      id="message"
                      name="message"
                      rows="3"
                      class="form-control md-textarea"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="text-center text-md-left footer-button">
                <button
                  type="button"
                  class="form-control btn-success estimate-submit col-4 btn"
                  @click="getInContact()"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-md"
                  ></div>
                  <span v-if="loading"> Sending</span>
                  <span v-else>{{ title }}</span>
                  <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
                </button>
              </div>
              <!--Grid row-->
            </form>

            <div class="status"></div>
          </div>
        </div>
      </section>
    </div>
    <div class="bg-blue p-2 text-white text-center">
      <div class="d-flex justify-content-center">
        <p class="pt-2 text-white">
          <a href="#">Privacy Policy</a> |
          <a href="#">Terms And Conditions</a> | <a href="#">Cookie Policy</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Footer",
  data() {
    return {
      loading: false,
      title: "Send",
      error: "",
      name: "",
      number: "",
      email: "",
      message: "",
    };
  },
  methods: {
    getInContact() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        axios
          .post(
            "/phpmailer/src/getInContact.php",

            qs.stringify({
              name: this.name,
              email: this.email,
              number: this.number,
              message: this.message,
            })
          )
          .then((response) => {
            this.loading = !false;
            this.title = "Sent :)";
            setTimeout(() => (this.title = "Send"), 5000);
            setTimeout(() => {
              this.loading = !true;
            }, 2000);
            this.name = "";
            this.email = "";
            this.number = "";
            this.message = "";
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
  },
};
</script>
